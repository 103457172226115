<template>
    <div class="block p-4 space-y-4 h-full">
        <div class="flex align-items justify-between notifications-header p-4">
            <div class="card-title d-flex align-items-center">
                <h3 class="fw-bold m-0 text-gray-800">
                    Notifications
                </h3>
            </div>
            <div class="card-toolbar m-0">
                <div class="d-flex align-items-center" v-if="checkIfExistUnreadNotifications">
                    <button class="primary-btn me-2 p-3" @click="markAllNotificationAsRead">
                        Marquer tout lu
                    </button>
                </div>
            </div>
        </div>

        <div v-if="isLoading" class="flex items-center justify-center h-full w-full">
            <!--<div class="fulfilling-square-spinner">
              <div class="spinner-inner"></div>
            </div>-->
            <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div class="p-4 notifications-list" v-if="notifications.length > 0 && !isLoading">
            <router-link v-for="item in notifications" :key="item.id"
                :to="{ name: 'NotificationDetails', params: { id: item.id } }" class="text-gray-800">
                <div class="notification-item" @click="markNotificationAsRead(item.id, item.read_at)">
                    <div>
                        <img src="/avatar_profile.jpg" alt="" class="notification-item__avatar">
                    </div>
                    <div class="flex sm:items-center flex-1 gap-2 sm:gap-3 flex-col sm:flex-row">
                        <div class="flex-1 center">
                            <div>
                                <p class="text-gray-800 font-medium">{{ item.title }}</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="flex items-center gap-2">
                                <span class="text-gray-500 time">
                                    {{ item.formatted_date }}
                                </span>
                                <div class="seen-status" :class="item.read_at == null ? 'unread' : 'read'">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </router-link>
        </div>

        <div class="card-body" v-if="notifications.length == 0 && !isLoading">
            <h4>Pas de notifications</h4>
        </div>

        <!--<div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mb-4"
            v-if="notifications.length > 0">
            
        </div>-->
        <!-- Pagination -->
        <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center mt-5"
            v-if="pagination.totalPages > 0 && notifications.length > 0 && !isLoading">

            <!-- Previous button (only show when there are more than 1 page) -->
            <li>
                <button
                    class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                    @click="prevPage()" :disabled="pagination.currentPage <= 1">
                    <span class="sr-only">Previous</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </li>

            <!-- Render pagination for single page -->
            <template v-if="pagination.totalPages === 1">
                <li>
                    <button
                        class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium z-10 bg-red-50 border-red-400 text-red-400">
                        1
                    </button>
                </li>
            </template>

            <!-- Render pagination numbers when there's more than one page -->
            <template v-else>
                <!-- Render pagination numbers -->
                <template v-if="pagination.totalPages > 6">
                    <!-- Always display the first page -->
                    <li>
                        <button
                            class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class="pagination.currentPage === 1 ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                            @click="handlePageChange(1)">
                            1
                        </button>
                    </li>

                    <!-- Show ellipsis if current page is far from first page -->
                    <li v-if="pagination.currentPage > 3">
                        <span class="mx-2">...</span>
                    </li>

                    <!-- Visible pages -->
                    <li v-for="page in getVisiblePages" :key="page">
                        <button
                            class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class="page == pagination.currentPage ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                            @click="handlePageChange(page)">
                            {{ page }}
                        </button>
                    </li>

                    <!-- Show ellipsis before last page if needed -->
                    <li v-if="pagination.currentPage < pagination.totalPages - 2">
                        <span class="mx-2">...</span>
                    </li>

                    <!-- Always display the last page -->
                    <li>
                        <button
                            class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class="pagination.currentPage === pagination.totalPages ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                            @click="handlePageChange(pagination.totalPages)">
                            {{ pagination.totalPages }}
                        </button>
                    </li>
                </template>

                <!-- Default behavior for 6 or fewer pages -->
                <template v-else>
                    <li v-for="page in pagination.totalPages" :key="page">
                        <button
                            class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class="page == pagination.currentPage ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                            @click="handlePageChange(page)">
                            {{ page }}
                        </button>
                    </li>
                </template>
            </template>

            <!-- Next button (only show when there are more than 1 page) -->
            <li>
                <button
                    class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                    @click="nextPage()" :disabled="pagination.currentPage >= pagination.totalPages">
                    <span class="sr-only">Next</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </li>
        </ul>
        <!-- End Pagination -->
    </div>
</template>

<script>
import { onMounted, ref, computed, reactive } from 'vue';
import notificationRequests from '../../services/requests/notification';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
export default {
    props: {
        linkPath: Function,
    },
    setup(props) {
        const notifications = ref([]);

        const checkIfExistUnreadNotifications = computed(() => {
            return notifications.value.some(item => item.read_at === null);
        })
        const route = useRoute();

        const currentRoute = route.path;
        const isLoading = ref(false);
        const pagination = reactive({
            currentPage: 1, // The current page from your API response
            totalPages: null, // The total number of pages from your API response
        })

        const getVisiblePages = computed(() => {
            const totalPages = pagination.totalPages;
            const currentPage = pagination.currentPage;
            let pages = [];

            // Show pages around the current page
            for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                pages.push(i);
            }

            return pages;
        })

        async function getNotifications() {
            isLoading.value = true;
            try {
                const response = await notificationRequests.getNotifications(pagination.currentPage);
                if (response) {
                    notifications.value = response.data.data;
                    pagination.totalPages = response.data.meta.last_page;
                }
            } catch (error) {

            } finally {
                isLoading.value = false;
            }
        }

        async function markNotificationAsRead(id, read_at) {
            if (read_at == null) {
                try {
                    const response = notificationRequests.markNotificationAsRead(id);
                    if (response) {

                    }
                } catch (error) {

                }
            } else {
                return false;
            }
        }
        async function markAllNotificationAsRead() {
            try {
                const response = notificationRequests.markAllNotificationAsRead();
                if (response) {
                    ElMessage({
                        message: "Notifications marquées comme lues avec succès.",
                        type: 'success',
                    })
                    getNotifications();

                }
            } catch (error) {
                ElMessage({
                    message: "Une erreur est survenue !.",
                    type: 'error',
                })
            }
        }

        function handlePageChange(page) {
            pagination.currentPage = page;
            // Fetch new data based on the new page number
            getNotifications();
        }

        function prevPage() {
            if (pagination.currentPage > 1) {
                pagination.currentPage--;
                getNotifications();
            }
        }
        function nextPage() {
            if (pagination.currentPage < pagination.totalPages) {
                pagination.currentPage++;
                getNotifications();
            }
        }

        onMounted(() => {
            props.linkPath(currentRoute);
            getNotifications();
        })
        return {
            notifications,
            getNotifications,
            markAllNotificationAsRead,
            markNotificationAsRead,
            checkIfExistUnreadNotifications,
            pagination,
            getVisiblePages,
            handlePageChange,
            nextPage,
            prevPage,
            isLoading
        }
    }
}
</script>
